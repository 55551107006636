.pull-left {
    float: left;
}

.form-control {
    width: 100%;
}

.hide {
    display: none;
}

.clear {
    clear: both ;
}


.fg-label sup a {
    color:blue;
    cursor: pointer;
}


