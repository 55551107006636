.Profissionais nav {
    width: 100%;
}



.Profissionais .pagination {
    margin: auto;
}

.Profissionais .card-none {
    background-color: #ffffff34 !important;
}

.Profissionais .media-content
{
    text-align: center;
    min-height: 250px;
    position: relative;
}

.Profissionais .media-content div
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media (max-width:1410px){
	.Profissionais {
        font-size: 13px !important;
    }

    .Profissionais .custom-control-label {
        font-size: 13px !important;
    }
}