.Cadastro .radio {
    float: left;
}

.Cadastro .field {
    padding: 3px;
}

.Cadastro input[type='text'],
.Cadastro input[type='email'],
.Cadastro input[type='password'],
.Cadastro input[type='number']
{
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    text-indent: 5px;
}

.Cadastro select {
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    padding:5px 3px !important;
}


.Cadastro input[type='date'] {
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    font-size:15px;
}


.Cadastro .step1 {
    margin-top: 0 !important;
}


.Cadastro #user_image {
    cursor: pointer;
}


.Cadastro .box {
    border: 2px #000000 solid;
}


.Cadastro .modal-dialog {
    max-width:900px !important;
}

.Cadastro .img-preview {
    overflow: hidden;
}

.descricao {
    height: 180px !important
}


.radio {
    float:left;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
}


.radio label {
   margin: 10px;
}

.Cadastro button {
    height: 41px !important
}

.css_separatorbar {
    background-color: #efefef;
    margin-top: 30px;
    padding:5px 5px 13px 5px !important; 
    border-radius: 8px;
}

.css_separatorbar small {
    font-size: 17px !important;
}


.css_checkbox {
    display: flex; 
    flex-direction: row-reverse;
    justify-content: left;
}

.css_checkbox label
{
    margin-left: 10px;
}

#btn_sv {
    height: 54px !important;
}


.Cadastro .btn-height {
    height: 54px !important;
}

.Cadastro .foto img {
    width: 100px !important;
    height: 100px !important;
    border: 4px solid #E0E0E0;
    margin: 3px;
}

.modal-footer {
    padding: 7px 3px 3px 3px !important;
}


.user_camera {
    position: relative;
    margin-top: -75px !important;
    margin-left: 125px !important;
}


@media (max-width:1350px){
	.Cadastro {
        font-size: 13px !important;
    }

    .Cadastro .form-control {
        font-size: 13px !important;
    }
}