

.SmartValuation .radio {
    float: left;
}

.SmartValuation .field {
    padding: 3px;
}

.SmartValuation input[type='text'],
.SmartValuation input[type='number']
{
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    text-indent: 5px;
}

.SmartValuation select {
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    padding:5px 3px !important;
}


.SmartValuation input[type='date'] {
    height: 41px !important;
    border: 1px #e0e0e0 solid !important;
    font-size:15px;
}




.descricao {
    height: 180px !important
}


.radio {
    float:left;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
}


.radio label {
   margin: 10px;
}

.SmartValuation button {
    height: 41px !important
}

.css_separatorbar {
    background-color: #efefef;
    margin-top: 30px;
    padding:5px 5px 13px 5px !important; 
    border-radius: 8px;
}

.css_separatorbar small {
    font-size: 17px !important;
}


.css_checkbox {
    display: flex; 
    flex-direction: row-reverse;
    justify-content: left;
}

.css_checkbox label
{
    margin-left: 10px;
}

#btn_sv {
    height: 54px !important;
}


@media (max-width:1350px){
	.SmartValuation {
        font-size: 13px !important;
    }

    .SmartValuation .form-control {
        font-size: 13px !important;
    }
}