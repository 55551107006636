.Busca nav {
    width: 100%;
}



.Busca .pagination {
    margin: auto;
}


.Busca .card-none {
    background-color: #ffffff34 !important;
}

.Busca .media-content
{
    text-align: center;
    min-height: 250px;
    position: relative;
}

.Busca .media-content div
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



@media (max-width:1410px){
	.Busca {
        font-size: 13px !important;
    }

    .Busca .custom-control-label {
        font-size: 13px !important;
    }
}
