.Home .la-2x {
    font-size: 38px !important;
    color: white;
}

.Home .align-items-center {
    height: 95px;
}

.Home .fs-fix {
    height: 100% !important;
}


input[type='checkbox'], 
input[type='checkbox']::after, 
input[type='checkbox']:checked:after {
    width: 22px;
    height: 22px;
    padding:0px !important;
}


.activity-card .media i {
    font-size: 22px;
    color: #ffffff;
}


.activity-card .media .border {
    font-size: 22px;
    background-color: #5d13b3;
    height: 62px;
}


.activity-card .media .ok  {
    font-size: 22px;
    background-color: #00a71c !important;
    height: 62px;
}

.desc-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; 
    white-space: pre-wrap;
    min-height: 108px;
}

.category-content {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
}

.category-content-2 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
}


.local-content:first-letter {
    text-transform: uppercase;
}


.edital-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
    white-space: pre-wrap;
}


.UserInfo span {
    text-align: left !important;
    margin: 2px;
    padding: 0px;
}


.pointer {
    cursor: pointer;
}


.skills_list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }


  .kind {
    white-space: nowrap  !important;
}